import { render, staticRenderFns } from "./handlepolicy.vue?vue&type=template&id=3433f039&scoped=true"
import script from "./handlepolicy.vue?vue&type=script&lang=js"
export * from "./handlepolicy.vue?vue&type=script&lang=js"
import style0 from "./handlepolicy.vue?vue&type=style&index=0&id=3433f039&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3433f039",
  null
  
)

export default component.exports