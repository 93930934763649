<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>

          <el-form-item label="行政区域" prop="county">
            <el-cascader  :append-to-body="false"  
              v-model="searchObj.county"
              size="mini"
              :options="levalRegion"
              :props="treeProps"
              @change="handleChange($event)"
              :show-all-levels="false"
              placeholder="请选择"
            >
            </el-cascader>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="政策文件"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <div class="">
      <el-table
        v-loading="loading"
        ref="table"
        :data="listData"
        style="width: calc(100% - 0.125rem);overflow-x: auto;"
        height="400px"
        :span-method="objectSpanMethod"
        :header-cell-style="{ backgroundColor: '#F5F7FA' }"
      >
        <el-table-column fixed type="index" label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="documentNumber" label="文号">
          <template slot-scope="scope">
            <el-input
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="text"
              v-model="scope.row.documentNumber"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="documentName" label="文件名称">
          <template slot-scope="scope">
            <el-input
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="text"
              min="0"
              v-model="scope.row.documentName"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="issuedDate" label="下达时间">
          <template slot-scope="scope">
            <el-date-picker
              :disabled="type == 'view' || type == 'charts'"
              size="mini"
              v-model="scope.row.issuedDate"
              type="date"
              format="yyyy-MM-DD"
              style="width: 100%"
              placeholder="请选择"
            >
            </el-date-picker>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="totalAmount" label="总金额（万元）">
          <template slot-scope="scope">
            <el-input
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
              min="0"
              v-model="scope.row.totalAmount"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <!-- 如果选择市,操作前加一个选择省级文件 -->
        <el-table-column
          v-if="level == 2 || row?.countyPids?.length != 2"
          align="center"
          width="180"
          prop="pid"
          label="省级文件"
        >
          <template slot-scope="scope">
            <el-select
              :disabled="type == 'view' || type == 'charts'"
              size="mini"
              @change="handleChange1($event, scope.$index)"
              v-model="scope.row.pid"
              placeholder="请选择"
            >
              <el-option v-for="item in options" :key="item.id" :label="item.documentNumber" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="name" width="120" label="操作" v-if="type == 'add'">
          <template slot-scope="scope">
            <el-link type="danger" @click="removeCol(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>

      <!-- <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination> -->
    </div>
    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _BudgetExpenditurePolicyPaperAdd,
  _BudgetExpenditurePolicyPaperInfo,
  _BudgetExpenditurePolicyPaperEdit,
  _BudgetExpenditurePolicyPaperSelect
} from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: '',
        county: null
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }],
        county: [{ required: true, message: '请选择行政区域', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: [],
      level: null,
      countyId: null,
      countryObj: {},
      pickerOptions: {
        defaultPickerValue(value) {
          console.log(value, '========value=======')
          return new Date(value)
        }
      }
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    async handleChange(value) {
      let obj = this.getPids(value)
      console.log(obj, '======handleChange========')

      this.level = obj.level
      this.countryObj = obj
      this.pid = obj.pid == 0 ? 0 : obj.id

      if (obj.level == 2) {
        //市
        this.options = await this.getProvinceList(obj.pid)
      }

      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })

      console.log(this.level, '======this.level===========')
    },
    getProvinceList(value) {
      let params = {
        year: 2022,
        id: value
      }
      _BudgetExpenditurePolicyPaperSelect(params).then(res => {
        console.log(res, '===========_BudgetExpenditurePolicyPaperSelect===============')
        if (res.code == 200) {
          this.options = res.data
        }
      })
    },
    handleChange1(value, index) {},
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        // id: 1
        startLevel: 1,
        endLevel: 2,
        inclusionLevel: false
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },

    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    async view(type, row) {
      this.type = type
      this.row = row

      console.log(row, '=====view==row=====')
      let countyPids = Number(row.countyPids.split(',')[1])
      console.log(countyPids, '=====countyPids=====')
      await this.getProvinceList(countyPids)
      // return
      this.title = type == 'view' ? '详情' : '查看统计表'
      await this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    btnClose() {
      this.$emit('refreshTable')
      this.$emit('handleBack1')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: this.countryObj.id, //县（市区）
            county: this.countryObj.name, //县（市区）
            documentNumber: '', //文号（省）
            documentName: '', //文件名称（省）
            issuedDate: '', //下达时间（省）
            totalAmount: 0, //总金额（省）
            orderNo: this.countryObj.orderNo, //排序
            countyPids: this.countryObj.pids, //pids
            pid: null
          }

          if (this.level == 2) {
            this.loading = true
            this.$refs.table.doLayout()
            this.loading = false
          }

          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _BudgetExpenditurePolicyPaperInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
          console.log(this.listData, '========获取详情==========')
        }
      })
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (columnIndex === 1) {
      //   if (row.deviceNum != 0) {
      //     return [row.deviceNum, 1]
      //   } else {
      //     // return [0, 0];
      //     if (row?.oneNum == 0) {
      //     } else {
      //       return [0, 0]
      //     }
      //   }
      // }
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          totalAmount: Number(ele.totalAmount)
        }
      })

      console.log(listData, '======提交的数据=== listData ===')
      // return
      switch (this.type) {
        case 'add':
          _BudgetExpenditurePolicyPaperAdd(listData).then(res => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.btnClose()
            } else {
              this.$message.error(res.message)
            }
          })
          break
        case 'edit':
          _BudgetExpenditurePolicyPaperEdit(listData[0]).then(res => {
            if (res.code == 200) {
              this.type = 'view'
              this.$message.success('修改成功')
              this.getBasicInfo()
            } else {
              this.$message.error(res.message)
            }
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
.small_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.import_opration {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 0 !important;
  background-color: #fff !important;
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;
  td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f7fa !important;
  }
  td:first-child {
    border-left: 0 !important;
  }
  td:last-child {
    border-right: 0 !important;
  }
}
</style>
